import React from "react";
import './PrivacyPolicy.scss';

const PrivacyPolicy = () =>{
    return(
        <section className="privacy-policy-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Ovate Textiles Privacy Policy</h1>
                        <p>At Ovate Textiles, we are committed to protecting the privacy of our customers and users. This privacy policy
                             outlines the types of personal information we collect, how it's used, and the measures we take to protect 
                             it.</p>
                        <p>
                            <b>Information Collection</b>
                            We may collect personal information such as name, address, email address, and phone number when you place 
                            an order. We may also collect payment information such as credit card or PayPal information when you make 
                            a purchase.
                        </p>
                        <p>
                            <b>Use of Information</b>
                            The information we collect is used for the following purposes:
                            <ul>
                                <li>To process and fulfill orders</li>
                                <li>To provide customer service and support</li>
                                <li>To send promotional materials and updates on new products and sales</li>
                                <li>To improve our website and customer experience</li>
                                <li>To comply with legal requirements</li>
                            </ul>
                        </p>
                        <p>
                            <b>Information Sharing and Disclosure</b>
                            We do not sell or rent personal information to third parties. We may share information with third-party 
                            service providers who assist us in operating our business and fulfilling orders. These service providers 
                            are bound by confidentiality agreements and are only permitted to use the information for the purposes of 
                            providing services to Ovate Textiles.

                            We may also disclose personal information if required by law or if we believe it is necessary to protect 
                            our rights and the rights of others.

                        </p>  
                        <p>
                            <b>Security Measures</b>
                            We take the protection of personal information seriously and have implemented appropriate technical, 
                            administrative, and physical security measures to safeguard it. However, no system can guarantee absolute 
                            security and we cannot guarantee that personal information will never be disclosed in ways not otherwise 
                            described in this policy.
                        </p>
                        <p>
                            <b>Cookies</b>
                            We use cookies to track website usage and to provide a better user experience. Cookies are small text files
                             that are stored on your device by a website. You may disable cookies in your browser settings, but this may affect the functionality of our website.
                        </p>
                        <p>
                            <b>Changes to Privacy Policy</b>
                            Ovate Textiles reserves the right to modify this privacy policy at any time without notice. Your continued use of the website following any changes to 
                            the privacy policy will constitute your acceptance of such changes.
                        </p>
                        <p> 
                            <b>Contact Us</b>
                            If you have any questions or concerns regarding our privacy policy, please contact us at +91 9990421542.
                            By using this website, you agree to the terms of this privacy policy. If you do not agree to these terms, please do not use this website.

                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PrivacyPolicy;