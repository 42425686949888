import React from "react";
import Banner from "../../../Utils/Banner/Banner";
import { images } from "../../../assets/images";
import PortfolioTab from "../../../Components/Portfolio/PortfolioTab";

const Portfolio = () =>{
    
    return(
       <>
        <Banner bgImage={images.portfoliobg} bgclass='banner' text="Portfolio"/>
        <PortfolioTab/>
       </> 
    )
}

export default Portfolio;