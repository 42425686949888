import axios from 'axios';
import React,{useState} from "react";
import { images } from "../../assets/images";
import emailjs from 'emailjs-com';

const ContactComponent = ()=>{

    const [email, setEmail] = useState(''); 
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
	const [ sent, setSent ] = useState(false)

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
		      

	// 	try {
	// 		await axios.post("http://localhost:4000/send_mail", {message:message,email:email,name:`${firstName} ${lastName}`,phone:phoneNumber})
    //         setSent(true) 
	// 	} catch (error) {
	// 		console.error(error)
	// 	}
	// }


    const submitHandler= (event) =>{ 
       
        event.preventDefault();
        emailjs.sendForm('service_iat12wr', 'template_6tl2l8j', event.target, 'pmeWI_54_bDioW291')
        .then((result) => {
            setSent(!sent);
            //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
        }, (error) => {
            console.log(error.text);
        });

    }
 
    return(
        <>
            <div className="contactwrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12 bg-yellow">
                            <div className="contact-info pad-h-100" data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">
                                <h4>Contact Directly</h4>
                                <div className="details">
                                    <ul>
                                        <li><a href="tel:+919990421542" style={{color:'#000', textDecoration:'none'}}><img src={images.phone} alt="" /> +91 9990421542</a></li>
                                        <li><a href="https://www.google.com/maps/place/CUD+Digital/@28.5829567,77.3139142,21z/data=!4m6!3m5!1s0x390ce566011c95a7:0xb0b40c3a70b21a9f!8m2!3d28.5829331!4d77.3141179!16s%2Fg%2F11sysg0p9t"  style={{color:'#000', textDecoration:'none'}}><img src={images.location} alt="" /> Noida, Uttar Pradesh</a></li>
                                        <li><a href="mailto:vishesh@gmail.com" style={{color:'#000', textDecoration:'none'}}><img src={images.globe} alt="" /> Vishesh@techqilla.com</a></li>
                                    </ul>
                                </div>
                                
                                <div className="socialIcons">
                                    <ul className="d-flex">      
                                        <li><a href="https://www.instagram.com/"><img src={images.instagram} alt="instagram icon" /></a></li>                               
                                        <li><a href="https://www.facebook.com/"><img src={images.facebook} alt="facebook icon" /></a></li>
                                        <li><a href="https://www.linkedin.com/"><img src={images.linkedin} alt="linkedin icon" /></a></li>
                                        <li><a href="https://twitter.com/"><img src={images.twitter} alt="twitter icon" /></a></li>                                        
                                        <li><a href="https://youtube.com/"><img src={images.youtube} alt="" /></a></li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12 pad-h-100">
                            <div className="form-wrapper " data-aos="fade-up" data-aos-offset="250" data-aos-duration="2000">                                
                                <div className="formtitle">
                                    <h4>Send us a Message</h4>
                                </div>
                                {!sent ? 
                                (
                                    <form onSubmit={submitHandler}>
                                        <div className="row">
                                            <div className="col-lg-6 col-12">
                                                <label>First name
                                                    <input type="text" name="first_name" placeholder="enter Your First Name" onChange={(e) => setFirstName(e.target.value)} required/>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label>Last name
                                                    <input type="text" name="last_name " placeholder="enter Your Last Name" onChange={(e) => setLastName(e.target.value)} required/>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label>Email address
                                                    <input type="email" name="email" placeholder="enter Your Email" onChange={(e) => setEmail(e.target.value)} required/>
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label>Phone
                                                    <input type="tel" name="phone" placeholder="enter Your Phone No." onChange={(e) => setPhoneNumber(e.target.value)} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required/>
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <label>Message
                                                    <input type="text" name="message" placeholder="what is it?...." onChange={(e) => setMessage(e.target.value)} required/>
                                                </label>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <label>
                                                    <input type="submit" value="Send message" className='blackbtn'/>
                                                </label>
                                            </div>
                                        </div>
                                    </form>
                                )
                                :
                                (<p style={{display:'flex',background:'#A4de02',padding:'2px 10px',borderRadius:'2px',color:'#000'}}>Mail sent, Thank you for reaching out We will talk soon.</p>)
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactComponent;