import React from "react";
import { images } from "../../../assets/images";

function Footer (){
    return(
        <footer>
            <section className="footer-wrapper">
            <div className="container info-sec">
                <div className="row ">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-12 pb-10">
                        <h3>Clothing you would like to wrap <br/>yourself in</h3>
                        <div className="socialIcons">
                            <ul className="d-flex">
                                <li><a href="https://www.facebook.com/"><img src={images.facebookYellow} alt="facebook icon" /></a></li>
                                <li><a href="https://www.linkedin.com/"><img src={images.linkedinYellow} alt="linkedin icon" /></a></li>
                                <li><a href="https://www.instagram.com/"><img src={images.instagramYellow} alt="instagram icon" /></a></li>
                                <li><a href="https://twitter.com/"><img src={images.twitterYellow} alt="twitter icon" /></a></li>   
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-12 pb-10">
                        <h3 className="padleft-30">Address</h3>
                        <div className="footer-address d-flex">
                            <img src={images.location} alt="location icon"/> 
                            <p>
                                <a href="https://www.google.com/maps/place/CUD+Digital/@28.5829567,77.3139142,21z/data=!4m6!3m5!1s0x390ce566011c95a7:0xb0b40c3a70b21a9f!8m2!3d28.5829331!4d77.3141179!16s%2Fg%2F11sysg0p9t" style={{color:'#fff', textDecoration:'none'}}>
                                C-37 , Sector 2,<br/>
                                Noida, 201301,<br/>
                                (U.P)
                                </a>
                            </p>
                        </div>
                        
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12 pb-10">
                        <h3 className="padleft-30">Say Hello</h3>
                        <div className="quickinfo">
                            <ul>
                                <li><a href="mailto:vishesh@gmail.com" style={{color:'#fff', textDecoration:'none'}}><img src={images.mail} alt="mail icon" /> Vishesh@techqilla.com</a></li>
                                <li><a href="tel:+919990421542" style={{color:'#fff', textDecoration:'none'}}><img src={images.phone} alt="phone icon" /> +91 99904 21542</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container copyright-sec">
                <div className="row">
                    <div className="col-lg-6 col-lg-6 col-md-6 col-12">
                        <p>All rights reserved Copyrights @ <a href="https://techqilla.com">TechQilla</a></p>
                    </div>
                    <div className="col-lg-6 col-lg-6 col-md-6 col-12">
                        <div className="footerNav">
                            <ul className="d-flex">
                                <li><a href="/terms-and-condition">Terms & condition</a></li>
                                <li><a href="/privacy-policy">Privacy policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </footer>
    )
}

export default Footer;