import React from "react";
import { images } from "../../assets/images";

const Slides = () =>{
    return(
        <>
           <div className="d-flex slides-section">            
                <img src={images.slide1} alt=""/>
                <img src={images.slide2} alt=""/>
                <img src={images.slide3} alt=""/>
                <img src={images.slide4} alt=""/>
                <img src={images.slide5} alt=""/>
                <img src={images.slide6} alt=""/>
                <img src={images.slide7} alt=""/>
                <img src={images.slide8} alt=""/>
           </div>
        </>
    )
}

export default Slides;