import React from "react";
import Banner from "../../../Utils/Banner/Banner";
import AboutInfo from "../../../Components/AboutInfo/AboutInfo";
import HowWeWork from "../../../Components/HowWeWork/HowWeWork";
import OurStory from "../../../Components/OurStory/OurStory";
import Team from "../../../Components/TeamSection/Team";
import { images } from "../../../assets/images";

const About =()=>{ 
    return(
        <>
            <Banner bgImage={images.Aboutbg} bgclass='banner' text="About Us"/>
            <AboutInfo/>
            <HowWeWork/>          
            <OurStory/>
            <Team/>    
        </>

    )
}

export default About;