import React from "react";
import { images } from "../../assets/images";
 

const AboutInfo = ()=>{
    return(
        <section className="about-sec bg-yellow">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="image-wrapper">
                            <img src={images.About1} alt="" data-aos="zoom-in" data-aos-offset="250" data-aos-duration="1000"/>
                            <div className="image-content" data-aos="fade-up" data-aos-offset="250" data-aos-duration="2000">
                                <h2>10+</h2>
                                <span>Years of <br/>experience</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                        <div className="about-info" data-aos="fade-left" data-aos-offset="250" data-aos-duration="2000">

                            <div className="title">
                                <h2>Who we are</h2>
                                <span>About Us</span>
                            </div>
                            <p>
                            The Ovate textile manufacturing company is located in the heart of New Delhi, India. We strive to deliver the best experience to our customers worldwide. In addition to manufacturing for brands, we allow you to completely customize your order. Sky isn't the limit for us and that helps us deliver results beyond your expectations. We are committed to offering you the best prices. While choosing a fabric or matching the design with the right material, we enjoy helping you and creating value for you. An ultimate clothing experience at your fingertips.
                            </p>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 box"> 
                                    <a href="https://www.google.com/maps/place/CUD+Digital/@28.5829567,77.3139142,21z/data=!4m6!3m5!1s0x390ce566011c95a7:0xb0b40c3a70b21a9f!8m2!3d28.5829331!4d77.3141179!16s%2Fg%2F11sysg0p9t">
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.4709 12.8989C13.0209 12.8989 13.4919 12.7029 13.8839 12.3109C14.2753 11.9196 14.4709 11.4489 14.4709 10.8989C14.4709 10.3489 14.2753 9.87793 13.8839 9.48593C13.4919 9.09459 13.0209 8.89893 12.4709 8.89893C11.9209 8.89893 11.4503 9.09459 11.0589 9.48593C10.6669 9.87793 10.4709 10.3489 10.4709 10.8989C10.4709 11.4489 10.6669 11.9196 11.0589 12.3109C11.4503 12.7029 11.9209 12.8989 12.4709 12.8989ZM12.4709 22.8989C9.78761 20.6156 7.78361 18.4946 6.45895 16.5359C5.13361 14.5779 4.47095 12.7656 4.47095 11.0989C4.47095 8.59893 5.27528 6.60726 6.88395 5.12393C8.49195 3.64059 10.3543 2.89893 12.4709 2.89893C14.5876 2.89893 16.4499 3.64059 18.0579 5.12393C19.6666 6.60726 20.4709 8.59893 20.4709 11.0989C20.4709 12.7656 19.8086 14.5779 18.4839 16.5359C17.1586 18.4946 15.1543 20.6156 12.4709 22.8989Z" fill="#FFCE00"/>
                                        </svg>
                                    
                                        <h4>Visit Us</h4>
                                        <p>C-37 , Sector 2,Noida,<br/> 201301, (U.P)</p>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 box">
                                    <a href="tel:919990421542">
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5166 10.9446C13.4535 11.0034 13.4028 11.0744 13.3677 11.1533C13.3325 11.2321 13.3137 11.3173 13.3121 11.4036C13.3106 11.4899 13.3265 11.5756 13.3588 11.6557C13.3911 11.7357 13.4393 11.8084 13.5003 11.8695C13.5614 11.9305 13.6341 11.9786 13.7141 12.011C13.7942 12.0433 13.8799 12.0592 13.9662 12.0577C14.0525 12.0561 14.1377 12.0373 14.2165 12.0021C14.2954 11.967 14.3664 11.9163 14.4252 11.8532L21.4709 4.80744V10.1132C21.4709 10.2837 21.5387 10.4472 21.6592 10.5677C21.7798 10.6883 21.9433 10.756 22.1138 10.756C22.2843 10.756 22.4478 10.6883 22.5683 10.5677C22.6889 10.4472 22.7566 10.2837 22.7566 10.1132V3.25602C22.7566 3.08552 22.6889 2.92201 22.5683 2.80145C22.4478 2.68089 22.2843 2.61316 22.1138 2.61316H15.2566C15.0861 2.61316 14.9226 2.68089 14.8021 2.80145C14.6815 2.92201 14.6138 3.08552 14.6138 3.25602C14.6138 3.42651 14.6815 3.59003 14.8021 3.71059C14.9226 3.83114 15.0861 3.89887 15.2566 3.89887H20.5623L13.5166 10.9446ZM3.97664 3.37002C4.93664 2.75287 6.16492 2.51716 7.37778 2.68259L7.58864 2.71087C7.9687 2.76272 8.33037 2.90654 8.64226 3.12985C8.95414 3.35316 9.20681 3.64921 9.37835 3.9923L10.4841 6.20544C10.7877 6.81258 10.8591 7.50967 10.685 8.16577C10.5109 8.82187 10.1033 9.39183 9.53864 9.76859L8.07464 10.7449C7.73435 10.9712 7.63921 11.2857 7.71464 11.5163C8.16892 12.8997 9.37749 14.7743 10.4309 15.8492C10.6289 16.0506 10.9855 16.1106 11.3532 15.9083L12.4709 15.2937C12.8416 15.0899 13.2488 14.9612 13.6693 14.9151C14.0898 14.869 14.5153 14.9063 14.9213 15.025C15.3274 15.1436 15.706 15.3412 16.0355 15.6065C16.365 15.8718 16.6389 16.1995 16.8415 16.5709L17.7346 18.2089C17.9216 18.5515 18.0206 18.9352 18.0227 19.3255C18.0248 19.7158 17.9299 20.1006 17.7466 20.4452C16.5038 22.7809 13.6692 24.0777 11.2641 22.5032C9.21206 21.1609 6.69035 18.9366 4.62721 15.3632C2.53406 11.7374 2.11492 8.59516 2.24435 6.3203C2.31892 5.00716 3.00378 3.99487 3.97664 3.37002Z" fill="#FFCE00"/>
                                        </svg>                                    
                                        <h4>Contact</h4>
                                        <p>+91-9876543210</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutInfo;