import { images } from "../../assets/images";

const processData=[
    {
        id:1,
        img:images.calendar,
        heading:'Appointment',
        para:'We begin with you spilling the beans. Having discussed your ideas, demands, and desires, we then study, analyze, and get details on fabrics, designs, and trims.'
    },
    {   
        id:2,
        img:images.material,
        heading:'Choose best cloth',
        para:'Following a discussion of your ideas, we develop a sample of your design. After evaluating fit, stitching, quality, design, then approving the sample, we move onto the next stage.   '
    },
    {   
        id:3,
        img:images.sewing,
        heading:'Production',
        para:'Now we begin with the bulk manufacturing as per the requirement, limiting the amount of waste. Transforming your ideologies into reality.'
    },
    {   
        id:4,
        img:images.dress,
        heading:'Final Result',
        para:'The moment you have been waiting for has finally arrived. You can expect your order to be dispatched within the specified time period. Happiness is on its way.'
    }
]
export default processData;