import React, { useState, useEffect} from 'react';
import {tabs,tabdata} from './PortfolioData';

const PortfolioTab=()=>{
    
    const [tabselected, settabselected] = useState('Women-Fashion');

    const handleChange =(event,categoryName)=>{
        event.preventDefault();
        settabselected(categoryName);            
        const links = document.querySelectorAll('.portfolio-tab-link ul li');
        for(let i=0;i<links.length;i++){
            links[i].classList.remove('active');
            event.target.classList.add('active');
        }
    }; 

    useEffect(() => {
        // settabselected('Women-Fashion');        
    });

    return (
    <div className="portfolio-tab bg-yellow pad-h-100">
      <div className="container">
          <div className="row">
              <div className="col-12" >
                  <div className="portfolio-tab-link">
                      <ul className="d-flex justify-content-center">
                          {
                              tabs.map((obj,index)=>{                                
                                return(
                                    <li key={obj.id} className={index===0 ? 'active' : ``}onClick={(event) => handleChange(event,obj.categoryName)}>{obj.name}</li>
                                )
                              })
                          }
                      </ul>
                  </div>
              </div>
              <div className="row portfolio-img-wrapper">
              {
                  tabdata.map((obj,index)=>{
                    return(
                        <div className={ obj.category === tabselected ? `${tabselected} col-lg-4 col-6 porfoliovisible` : ' portfolio-hide'} key={index} >
                            <div className="portfolio-inner-wrapper" style={{backgroundImage:`url(${obj.img})`}}>
                                <div className="imagesinfo">
                                    <h4>{obj.productName}</h4>
                                    <p>{obj.productDescription}</p>
                                </div>
                            </div>                                        
                        </div>
                      )
                  })
              }
              </div>
              
          </div>
      </div>
    </div>
    );
}


export default PortfolioTab;