import React from "react";
import HomeBanner from "../../../Components/HomeBanner/HomeBanner";
import About from "../../../Components/About/About";
import PortfolioComponent from "../../../Components/PortfolioComponent/PortfolioComponent";
import VideoComponent from "../../../Components/VideoComponent/VideoComponent";
import Testimonials from "../../../Components/Testimonial/Testimonial";
import OurCollection from "../../../Components/OurCollection/OurCollection";
import Slides from "../../../Components/Slides/Slides";

const Home = ()=>{

    return(
        <>
            <HomeBanner/>
            <About/>
            <PortfolioComponent/>
            <OurCollection/> 
            <Testimonials/>
            <Slides/>
        </>
    )

}

export default Home;