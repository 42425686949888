import React from 'react';
import './Banner.scss';
import { Link } from 'react-router-dom';

const Banner = (props) => {
  return(
  <div className={props.bgclass} style={{backgroundImage:`url(${props.bgImage})`}}>
    {props.bgcontent}
    <div className='banner-wrapper'  data-aos="fade-down" data-aos-offset="250" data-aos-duration="1000">
        <h2 className='heading'>{props.text} </h2>    
        {props.text &&
          <span><Link to="/">Home</Link> | {props.text}</span>  
        }            
    </div>
  </div>
  )
};

export default Banner;