import React from "react";
import ServicesData from './ServicesData'

const ServiceComponent =()=>{
    return(
        <section className="service-sec pad-h-100 bg-yellow">
            <div className="container">
                <div className="row">
                    
                    {ServicesData  ?
                        ServicesData.map((obj,index)=>{
                            return(
                                <div className="col-lg-4 col-12" key={index} data-aos="slide-up" data-aos-offset="250" data-aos-duration="1000">
                                    <div className="service-box">
                                        <img src={obj.img} alt="" />
                                        <h3>{obj.heading}</h3>
                                        <p>{obj.para}</p>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <>NO data</>
                    }
                </div>
            </div>
        </section>
    )
}

export default  ServiceComponent;