import React from "react";
import Banner from "../../../Utils/Banner/Banner";
import Testimonail from "../../../Components/Testimonial/Testimonial";
import { images } from "../../../assets/images";
import ServiceComponent from "../../../Components/ServiceComponent/ServiceComponent";
import OurCollection from "../../../Components/OurCollection/OurCollection";
import ServiceIntro from "../../../Components/ServiceIntro/serviceIntro";


const Service = ()=>{
    return(
        <>
            <Banner bgImage={images.servicebg} bgclass='banner' text="Service"/>
            <ServiceIntro/>
            <ServiceComponent/> 
            <OurCollection/>
            <Testimonail/>
        </>
    )
}
export default Service;