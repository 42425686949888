import React, {useEffect} from "react";
import { images } from "../../assets/images";
import TestData from "./TestimonialData";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Testimonials = ()=>{

    const items={

        nav:true,
        navText:
        [`<svg width="41" height="9" viewBox="0 0 41 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.482353 4.11959C0.28709 4.31485 0.28709 4.63143 0.482353 4.82669L3.66433 8.00868C3.8596 8.20394 4.17618 8.20394 4.37144 8.00868C4.5667 7.81341 4.5667 7.49683 4.37144 7.30157L1.54301 4.47314L4.37144 1.64471C4.5667 1.44945 4.5667 1.13287 4.37144 0.937607C4.17618 0.742345 3.8596 0.742345 3.66433 0.937607L0.482353 4.11959ZM40.5972 3.97314L0.835907 3.97314L0.835907 4.97314L40.5972 4.97314L40.5972 3.97314Z" fill="black"/>
        </svg>
        `,`<svg width="41" height="9" viewBox="0 0 41 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.4837 4.8267C40.679 4.63144 40.679 4.31485 40.4837 4.11959L37.3017 0.937611C37.1065 0.742348 36.7899 0.742348 36.5946 0.937611C36.3994 1.13287 36.3994 1.44946 36.5946 1.64472L39.423 4.47314L36.5946 7.30157C36.3994 7.49683 36.3994 7.81342 36.5946 8.00868C36.7899 8.20394 37.1065 8.20394 37.3017 8.00868L40.4837 4.8267ZM0.368896 4.97314H40.1302V3.97314H0.368896V4.97314Z" fill="black"/>
        </svg>
        `],

        responsive : {
            // breakpoint from 0 up
            0 : {
                items:1,
            },
            // breakpoint from 480 up
            480 : {
                items:1,
            },
            // breakpoint from 768 up
            1000 : {
                items:2
            }
        }
    }        

    useEffect(() => {

        var cursorinner = document.querySelectorAll('.cursor2');        


        cursorinner.forEach(element => {
            console.log(element);        
            document.addEventListener('mousemove', function(e){
                var x = e.clientX;
                var y = e.clientY;
                element.style.left = x + 'px';
                element.style.top = y + 'px';
            });
    
            document.addEventListener('mousedown', function(){
                element.classList.add('cursorinnerhover')
            });
    
            document.addEventListener('mouseup', function(){
                element.classList.remove('cursorinnerhover')
            });
        });
        


    });


    return(
        <>
        
        <section className="Testimonail-section bg-yellow">            
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-12"> 
                        <div className="sideimg" data-aos="zoom-in" data-aos-offset="250" data-aos-duration="2000">
                            <img src={images.testimonialside} alt="" />
                        </div>                       
                    </div>
                    <div className="col-xl-8 col-lg-8  col-md-7 col-sm-8 col-12">                            
                        <div className="testimoninal-content-wrapper">
                            <div className="title"  data-aos="fade-down" data-aos-offset="250" data-aos-duration="1000">
                                <span>TESTIMONIALS</span>
                                <h2>Reviews From Our Clients</h2>
                            </div>
                            <div className="testimonial-slider addCursor">
                                <div className="cursor2"></div> 
                                <OwlCarousel className='owl-carousel owl-theme'{...items} items={2} loop margin={20} dots={false}>
                                {TestData.map((obj,index)=>{
                                    return(
                                        <div className="testimonial-box item" key={index} data-aos="fade-left" data-aos-offset="250" data-aos-duration="1000">
                                            <ul className="stars d-flex" style={{listStyle:'none'}}>
                                                
                                                {[...Array(obj.stars)].map((x, i) =>
                                                    <li key={i}><img src={images.star} alt='staricon' /> </li>
                                                )}
  
                                            </ul>
                                            <p className="client-review">{obj.para}</p>
                                            <div className="profile">
                                                <div className="d-flex">
                                                    <div className="client-img">
                                                        <img src={obj.img} alt="" />
                                                    </div>
                                                    <div className="client-info">
                                                        <h5>{obj.Name}</h5>
                                                        <span>{obj.title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    )
                                })}
                                </OwlCarousel>                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Testimonials;