import * as React from 'react';

function Primarybtn(props) {
    return (        
        <a href={props.linkto ? props.linkto:'#'}>
            <button 
            onClick={props.onClick} 
            className={props.btnclass}
            type={props.btnType??"button"}  
            data-aos="fade-up" 
            data-aos-offset="50" 
            data-aos-duration="2500" >
                {props.btntext}
            </button>
        </a>

    );
}
export default Primarybtn;