import React from 'react';
import Banner from '../../Utils/Banner/Banner';
import Primarybtn from '../../Utils/Buttons/Primarybtn';
import video from '../../assets/video.mp4';
import './HomeBanner.scss';

const HomeBanner = () => {
 

    return(
        <>
            <Banner  bgclass='MainBanner' bgcontent={
                <>
                <video controls={false} autoPlay muted loop>
                    <source src={video} type="video/mp4"/>
                </video>
                <div className="overlay"></div>
                <div className='banner-wrapper'  data-aos="fade-down" data-aos-offset="250" data-aos-duration="1000">
                    <h2 className='heading'>We stitch what you can stick to</h2>
                    <p>Whether you're looking for comfort, fashion, style or customization, we've got you covered. Let us make it sew for you. As a quality manufacturer, we strive to meet or exceed your expectations.</p>
                    <Primarybtn btntext="Make an appointment" btnclass='appointmentbtn2' linkto='/contact'/>
                </div>
                </>
            }/>

        </>
    )

};

export default HomeBanner;