import { images } from "../../assets/images";
const team=[
    {
        id:1,
        img:images.Team1,
        head:'Head Malson',
        title:'Master Tailor'
    },
    {
        id:2,
        img:images.Team2,
        head:'Kevin Malson',
        title:'Fashion Designer'
    },
    {
        id:2,
        img:images.Team3,
        head:'Neena Peter',
        title:'Fashion Designer'
    }
]
export default team;