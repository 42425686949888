
import Aboutbg from './Aboutbg.png';
import About1 from './about1.png';
import calendar from './calendar.png';
import dress from './dress.png';
import material from './material.png';
import sewing from './sewing.png';
import story from './story.png';
import Team1 from './Team1.png';
import Team2 from './Team2.png';
import Team3 from './Team3.png';
import subserviceitem from './sub-service-item.png';

export const aboutImage ={
    Aboutbg,
    About1,
    calendar,
    dress,
    material,
    sewing,
    story,
    Team1,
    Team2,
    Team3,
    subserviceitem
}