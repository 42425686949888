import React from "react";
import Banner from "../../../Utils/Banner/Banner";
import { images } from "../../../assets/images";
import ContactComponent from "../../../Components/ContactComponent/ContactComponent";



const Contact = ()=>{
    return(
        <>
        <Banner bgImage={images.contactbg} bgclass='banner' text='Contact'/>
        <ContactComponent/>
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0738651161946!2d77.32358577519021!3d28.567544575700335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce437992ea39f%3A0xa26bb41d89eacf5b!2sWorlds%20of%20Wonder!5e0!3m2!1sen!2sin!4v1673526582399!5m2!1sen!2sin" 
        width="100%" 
        height="450" 
        style={{border:'0',filter: 'invert(90%)'}}
        allowFullScreen="" 
        loading="lazy" 
        title="Our Map"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>

    )
}
export default Contact;
