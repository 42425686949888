import React from "react";
import { images } from "../../assets/images";
import Primarybtn from "../../Utils/Buttons/Primarybtn";

const About = () =>{
    return(
        <section className="about pad-h-100 " data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="abt-img">
                            <img src={images.about1} alt="" data-aos="fade-right" data-aos-offset="250" data-aos-duration="1000"/>
                            <img src={images.about2} alt="" data-aos="fade-right" data-aos-duration="4000"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6  col-md-6  col-sm-6 col-12 d-flex align-items-center">
                        <div className="about-wrapper ">
                            <div className="title" data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">
                                <h2>We deliver experience</h2>
                                <span>Clothing customization</span>
                            </div>
                            <p>We are committed to providing you with a wonderful experience at an affordable price. Putting an emphasis on stitching and inches to comfort and style is what sets us apart. Throughout the product quality evaluation process, we cover planning, control, quality assurance, and improvement, and dispatch the order on schedule. Whether you're a brand or an individual, local or overseas, we have what you need.</p>
                            <Primarybtn btnclass="blackbtn" btntext="Learn about us"  linkto="/about" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;