import servicebg from './servicebg.png';
import production from './Production.png';
import pattern from './Pattern.png';
import cutting from './Cutting.png';
import sewingmachine from './sewing.png';
import custom from './custom.png';


export const services ={
    servicebg,
    production,
    pattern,
    cutting,
    sewingmachine,
    custom,
}