import React from "react";
import './TermsAndCondition.scss';

const TermsAndCondition = () =>{
    return(
        <section className="terms-and-cond-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Ovate Textile Terms and Condition</h1>
                        <p>Welcome to Ovate Textiles, a leading clothing manufacturing company. By accessing and using our website, 
                            you agree to be bound by the following terms and conditions. Please read them carefully.</p>
                        <p>
                            <b>Copyright and Trademark Information</b>
                            All content on this website, including text, images, logos, and design elements, is the property of Ovate 
                            Textiles and is protected by copyright and trademark laws. Any unauthorized use of the website content is 
                            strictly prohibited and may result in legal action.
                        </p>
                        <p>
                            <b>Use of Website Content</b>
                            The website content is for personal and non-commercial use only. You may not modify, copy, distribute, 
                            transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any 
                            information, software, products or services obtained from the website.
                        </p>
                        <p>
                            <b>Product Information and Pricing</b>
                            The information and pricing provided on this website are subject to change without notice. Ovate Textiles cannot be held responsible for any typographical errors or inaccuracies that may occur.

                        </p>  
                        <p>
                            <b>Order Fulfillment and Shipping</b>
                            Orders will be fulfilled and shipped within the specified time frame. Ovate Textiles is not responsible for any shipping or delivery delays that may occur due to factors beyond its control. Shipping fees and estimated delivery times will be provided during the checkout process.
                        </p>
                        <p>
                            <b>Payment Terms</b>
                            Ovate Textiles accepts payment via credit card, PayPal, Stripe or bank transfer. Any applicable fees will be clearly stated during the checkout process.
                        </p>
                        <p>
                            <b>Liability Disclaimer</b>
                            Ovate Textiles shall not be liable for any damages or losses that may occur as a result of using this website or purchasing products from it.
                        </p>
                        <p> 
                            <b>Privacy Policy</b>
                            Ovate Textiles takes the protection of your personal information seriously. Please refer to our privacy policy for more information on the types of personal information we collect, how it's used, and the security measures in place to protect it.
                        </p>
                        <p>
                            <b>Governing Law</b>
                            These terms and conditions shall be governed by and construed in accordance with the laws of the state. Any disputes arising from the use of this website or the purchase of products from it will be subject to the exclusive jurisdiction of the courts.
                        </p>
                        <p>
                            <b>Changes to Terms and Conditions</b>
                            Ovate Textiles reserves the right to modify these terms and conditions at any time without notice. Your continued use of the website following any changes to these terms and conditions will constitute your acceptance of such changes.

                        </p>
                        <p>
                            <b>Contact Us</b>
                            If you have any questions or concerns regarding these terms and conditions, please contact us at  +91 9990421542
                            By using this website, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use this website.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TermsAndCondition;