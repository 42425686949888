import { images } from "../../assets/images";

const ServicesData =[
    {
        id:1,
        img:images.production,
        heading:'Apparel',
        para:'From classic staples like crisp, button-up shirts and sleek, tailored pants to the latest fashion trends like statement dresses and bold, colorful outerwear, our apparel is made with both form and function in mind.'
    },{
        id:2,
        img:images.pattern,
        heading:'Customization',
         para:"Whether you're looking to add a personal touch to your wardrobe or create a unique gift, our customization services offer endless possibilities to make your clothing truly one-of-a-kind."
    },{
        id:3,
        img:images.sewingmachine,
        heading:'Denim',
         para:"With our Denim collection, you'll find the perfect blend of style and durability. Designed with precision and crafted from the highest quality materials, our denim garments will stand the test of time."
    },{
        id:4,
        img:images.cutting,
        heading:'Accessories',
         para:"Complete your look with our Accessories section, where bags, hats, and scarfs are more than just an afterthought. Get a personal touch by accessorizing with our unique and diverse selection.",
    },{
        id:5,
        img:images.custom,
        heading:'Formal wear',
         para:"Step out in style and make a lasting impression with our Formal wear collection, where elegance meets sophistication. A wide range of suits, tuxedos, and formal wear, we have something for every occasion"     
    }
]


export default ServicesData;