import { Navigate, useRoutes } from "react-router-dom"; 
import Layout from "../View/Layout/Layout";
import { Home,About,Portfolio,Contact,Service,ErrorPage,TermsAndCondition,PrivacyPolicy} from "../View/Pages";


const Routes = () => {
  const element = useRoutes([
    
    {
      path: "/",
      element: <Layout />,
      children:[
        {
          path:"/",            
          element:<Home/>
        },
        {
          path:"/about",            
          element:<About/>
        },{
          path:"/portfolio", 
          element:<Portfolio/>
        },{
          path:'/contact',
          element:<Contact/>
        },{
          path:'/service',
          element:<Service/>
        },{
          path:'/terms-and-condition',
          element:<TermsAndCondition/>
        },{
          path:'/privacy-policy',
          element:<PrivacyPolicy/>
        }
      ]
    },
    {
      path:'/404',
      element:<ErrorPage/>
    },
    {
      path: "*",
      element: <Navigate to="/404"  />,
    },
  ]);

  return element;
};

export default Routes;