import portfoliobg from './portfoliobg.png';
import portfolioPic1 from './portfolio-samples/1.png'
import portfolioPic2 from './portfolio-samples/2.png'
import portfolioPic3 from './portfolio-samples/3.png'
import portfolioPic4 from './portfolio-samples/4.png'
import portfolioPic5 from './portfolio-samples/5.png'
import portfolioPic6 from './portfolio-samples/6.png'
import portfolioPic7 from './portfolio-samples/7.png'
import portfolioPic8 from './portfolio-samples/8.png'
import portfolioPic9 from './portfolio-samples/9.png'
import portfolioPic10 from './portfolio-samples/10.png'
import portfolioPic11 from './portfolio-samples/11.png'
import portfolioPic12 from './portfolio-samples/12.png'
import portfolioPic13 from './portfolio-samples/13.png'
import portfolioPic14 from './portfolio-samples/14.png'
import portfolioPic15 from './portfolio-samples/15.png'


export const Portfolio ={
    portfoliobg,
    portfolioPic1,
    portfolioPic2,
    portfolioPic3,
    portfolioPic4,
    portfolioPic5,
    portfolioPic6,
    portfolioPic7,
    portfolioPic8,
    portfolioPic9,
    portfolioPic10,
    portfolioPic11,
    portfolioPic12,
    portfolioPic13,
    portfolioPic14,
    portfolioPic15,
}
