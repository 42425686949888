import logo from './logo.png';
import { header } from './header';
import { home } from './home';
import { footer } from './footer';
import { aboutImage } from './about';
import { Portfolio } from './portfolio';
import { Contact } from './contact';
import {Icon}from './icon';
import {services} from './services';
import { SVG } from './SVG';
import { Categories } from './product-category';
import sadness from './sadness.png';
import videoOvate from './video.mp4';
import darkLogo from './logo-ovate-black.png';
import slide1 from './home/slider-1.png';
import slide2 from './home/slider-2.png';
import slide3 from './home/slider-3.png';
import slide4 from './home/slider-4.png';
import slide5 from './home/slider-5.png';
import slide6 from './home/slider-6.png';
import slide7 from './home/slider-7.png';
import slide8 from './home/slider-8.png';


export const images = {
    logo,
    darkLogo,
    ...header,
    ...home,
    ...footer,
    ...aboutImage,
    ...Portfolio,
    ...Contact,
    ...services,
    ...Icon,
    ...SVG,
    sadness,
    videoOvate,
    ...Categories,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
};