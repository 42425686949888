import Primarybtn from '../../Utils/Buttons/Primarybtn';
import PortfolioData from './PortfolioData';

const PortfolioComponent =()=>{

    return(
        <section className='Portfolio'>
            <div className="container">
                <div className="row text-center justify-content-center">
                    <div className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-12">
                        <div className="title"  data-aos="fade-down" data-aos-offset="250" data-aos-duration="1000">
                            <h2 >Our Portfolio</h2>
                            <p>A mood board to inspire your design ideas. All your designs made into reality under one roof. Let us create your taste.</p>
                        </div>
                   </div>
                    <div className="row">
                        {PortfolioData.map((obj)=>{
                            return(
                                
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 portfolio-wrapper" key={obj.id}  data-aos="zoom-in" data-aos-offset="250" data-aos-duration="1000">
                                    <img src={obj.img} alt="" />
                                    <h4>{obj.head} </h4>
                                    <p>{obj.content}</p>
                                </div>
                                
                            );
                        })}
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <Primarybtn btnclass="blackbtn" btntext="View More" linkto='/portfolio'/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PortfolioComponent;