import facebookYellow from './facebook.png';
import instagramYellow from './insta.png';
import linkedinYellow from './Linkedin.png';
import twitterYellow from './twitter.png';
import location from './location.png';

export const footer ={
    facebookYellow,
    instagramYellow,
    linkedinYellow,
    twitterYellow,
    location
}