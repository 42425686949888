import { images } from "../../assets/images";


const PortfolioData =[
    {
        id:1,
        img:images.Portfolio1,
        head:'The Purple Dress',
        content :'Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas placerat aliquam nulla. Amet dolor tempor tellus quis nec'
    },
    {
        id:2,
        img:images.Portfolio2,
        head:'The Blue Dress',
        content :'Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas placerat aliquam nulla. Amet dolor tempor tellus quis nec'
    
    },
    {
        id:3,
        img:images.Portfolio3,
        head:'The Floral Dress',
        content :'Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas placerat aliquam nulla. Amet dolor tempor tellus quis nec'
    
    },
]

export default PortfolioData;