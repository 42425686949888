import React from "react";

const ServiceIntro =()=>{
    return(
        <div className="service-intro">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-7 col-lg-7 col-12">
                        <div className="title">
                            <h2>Measured, Made and Fitted by Us</h2>
                        </div>
                    </div>
                    <div className="col-xl-5 col-xl-5 col-12">
                        <p>
                        Ovate understands the sentimental attachment you have to your clothes and well-loved fabrics. For that reason, we offer a range of professional services. Our team of skilled tailors and designers is here to give your wardrobe a new lease on life.
                        </p>
                        <p> 
                        From vintage to modern, rely on us to provide all of your textile needs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceIntro;