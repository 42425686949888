import facebook from './Facebook.png';
import instagram from './Instagram.png';
import linkedin from './Linkedin.png';
import twitter from './Twitter.png';
import youtube from './Youtube.png';
import globe from './globe.png';



export const Icon = {
    facebook,
    instagram,
    linkedin,
    twitter,
    youtube,    
    globe
}