import React from "react";
import { images } from "../../assets/images";

const OurStory =()=>{
    return(
        <section className="pad-h-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 d-flex align-items-center">
                            <div className="about-info" data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">

                                <div className="title">
                                    <h2>Our story </h2>
                                </div>
                                <p>
                                Our story began in the heart of the textile industry, where a group of passionate individuals came together with a vision to create something truly special. From the very beginning, we were determined to produce high-quality, sustainable garments that would not only look and feel great, but also make a positive impact on the world. We began our journey by sourcing the finest, ethically-sourced materials from around the globe, carefully selecting only the best for our garments. our story is one of passion, dedication, and commitment to creating a better future through fashion.
                                </p>
                                
                            </div>
                        </div> 
                        <div className="col-lg-6 col-12">
                            <div className="image-wrapper"  data-aos="zoom-in" data-aos-offset="250" data-aos-duration="1000">
                                <img src={images.story} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default OurStory;