import React from "react";
import { images } from "../../../assets/images";
import Primarybtn from "../../../Utils/Buttons/Primarybtn";

const ErrorPage = () =>{
    return(
        <>
        <section className="error-section">
            <div className="container pad-h-100">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h2>Sadness is here....</h2>
                        <span>Because the page you are trying to search is not found</span>
                        <img src={images.sadness} alt="404 image" style={{width:'50%'}}/><br/>
                        <Primarybtn btnclass='blackbtn' btntext="Go to home" linkto="/"/>
                    </div>
                    
                </div>
            </div>
        </section>
        </>
    )
}

export default ErrorPage;