import { images } from "../../assets/images";

const TestData=[
    {
        id:1,
        stars :5,
        para:'I have been using Ovate for all of my clothing production needs for the past few years and have never been disappointed. The quality of their fabrics and attention to detail is unmatched. Highly recommend it ! ',
        img:images.client1,
        Name:'Kate Parkson',
        title:'Client'
    },{
        id:2,
        stars :4,
        para:`While I was skeptical about changing to a new clothing manufacturing textile company, Ovate Textiles has proved to be the best choice for me. Their customer service is exceptional and their products are always of the highest quality. `,
        img:images.client2,
        Name:'Steve Kroft',
        title:'Client'
    },{
        id:3,
        stars :3,
        para:`In my role as a small business owner, I needed a clothing manufacturing textile company that could help me make my designs a reality at an affordable price. Ovate has exceeded my expectations in every way.`,
        img:images.client1,
        Name:'Megan Handrickson',
        title:'Client'
    }
]

export default TestData;