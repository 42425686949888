import { images } from "../../assets/images";

export const tabs =[
    {
    id:1,
    name:`Women's Fashion`,
    categoryName:`Women-Fashion`,
    about:'Adipiscing elit, sed do euismod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    banner:images.category1,
    },{
    id:2,
    name:`Color Trends`,
    categoryName:`Color-Trends`,
    about:'Adipiscing elit, sed do euismod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    banner:images.category2,
    },{
    id:3,
    name:`Special Event`,
    categoryName:`Special-Event`,
    about:'Adipiscing elit, sed do euismod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    banner:images.category3,
     },{
    id:4,
    name:`Business style`,
    categoryName:`Business-style`,
    about:'Adipiscing elit, sed do euismod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
    banner:images.category1,
    },
]

export const tabdata=[
    {
        id:1,
        img:images.portfolioPic1,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:2,
        img:images.portfolioPic2,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:3,
        img:images.portfolioPic3,
        category:`Color-Trends`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:5,
        img:images.portfolioPic5,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:6,
        img:images.portfolioPic6,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:7,
        img:images.portfolioPic7,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas. ",
    },{
        id:8,
        img:images.portfolioPic8,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas. ",
    },{
        id:9,
        img:images.portfolioPic9,
        category:`Women-Fashion`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:10,
        img:images.portfolioPic10,
        category:`Color-Trends`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:11,
        img:images.portfolioPic11,
        category:`Color-Trends`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:12,
        img:images.portfolioPic12,
        category:`Color-Trends`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:13,
        img:images.portfolioPic13,
        category:`Special-Event`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:14,
        img:images.portfolioPic14,
        category:`Special-Event`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:15,
        img:images.portfolioPic15,
        category:`Business-style`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },{
        id:16,
        img:images.portfolioPic13,
        category:`Business-style`,
        productName:'Golden Era',
        productDescription:"Lorem ipsum dolor sit amet consectetur. Potenti vitae commodo egestas.",
    },
]
