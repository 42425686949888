import Routes from "./Routes/Routes";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  return (
    <div>
      <Routes/>
    </div>
  );
}

AOS.init();

export default App;
