import React from "react";
import processData from "./ProcessData";

const HowWeWork = ()=>{
    return(
        <section className="how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="title" data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">
                            <h2>How we work</h2>
                            <span>OUR PROCESS</span>
                        </div>
                    </div>
                    {   
                        processData ?
                        processData.map((obj,index)=>{
                            return(
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 work-box" key={obj.id} data-aos="fade-up" data-aos-offset="250" data-aos-duration={800*index}>
                                    <img src={obj.img} alt="" />
                                    <h3>{obj.heading}</h3>
                                    <p>{obj.para}</p>
                                </div>
                            )
                        })
                        :
                        <p>No Data Recieved</p>
                    }
                    
                </div>
            </div>
        </section>
    )
}

export default HowWeWork;