import React from "react";
import { Link } from 'react-router-dom';

const navigationList =[
    {
        id:1,
        nav:'Home',
        link:'/'
    },{
        id:2,
        nav:'About',
        link:'/about'
    },{
        id:3,
        nav:'Portfolio',
        link:'/portfolio'
    },{
        id:4,
        nav:'Contact',
        link:'/contact'
    },{
        id:5,
        nav:'Services',
        link:'/service'
    },
]

const Navigation = ()=>{    
    let  location= window.location.href;
    return(
        <>
            {navigationList.map((obj,index)=>{
                return(<li key={index}><Link to={obj.link} 
                        style={{color:location.includes('terms-and-condition') || location.includes('privacy-policy') ? "#000000" : '#ffffff'}}
                        >{obj.nav}</Link></li>)
            })}
        </>
    )
}

export default Navigation;