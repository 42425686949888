import React from "react";
import { images } from "../../assets/images";
import { tabs } from "../Portfolio/PortfolioData";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";


const OurCollection =()=>{
    const items={responsive : {
            // breakpoint from 0 up
            0 : {
                items:1,
            },
            // breakpoint from 480 up
            480 : {
                items:1,
            },
            // breakpoint from 768 up
            1000 : {
                items:2
            }
        }
    }
    return(
        <>
        <div className="our-collection pad-h-100">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="title" data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">
                            <span>Browse Our Collection</span>
                        </div>
                    </div>  
                    <div className="col-lg-8 col-12 collection-slider addCursor">    
                        <div className="cursor2"></div>      
                        <Swiper                                             
                            navigation={true}
                            loop={false}
                            cssMode={true}
                            mousewheel={true}                           
                            slidesPerView={2}
                            spaceBetween={30}
                            modules={[ Navigation,Mousewheel]}
                            breakpoints={{
                                320: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                1024: {
                                  slidesPerView: 2,
                                  spaceBetween: 30,
                                },
                            }}
                            className="mySwiper"
                        >
                            {
                                tabs.map((obj,index)=>{
                                    return(
                                        <SwiperSlide>
                                        <div className="item" key={index} data-aos="zoom-in" data-aos-offset="250" data-aos-duration="1000">
                                            <h3>{obj.name}</h3>
                                            <p>{obj.about}</p>
                                            <Link to="/portfolio"><img src={images.arrow} alt="" style={{width:'5%'}}/></Link>                                 
                                            <img src={obj.banner} alt="" />
                                        </div> 
                                        </SwiperSlide>
                                    )
                                })
                            } 
                            
                        </Swiper>    
                        {/* <OwlCarousel className='owl-carousel owl-theme' {...items}  stagePadding={50} nav={false} items={2} loop margin={20} dots={false}>
                            {
                                tabs.map((obj,index)=>{
                                    return(
                                        <div className="item" key={index} data-aos="zoom-in" data-aos-offset="250" data-aos-duration="1000">
                                            <h3>{obj.name}</h3>
                                            <p>{obj.about}</p>
                                            <Link to="/portfolio"><img src={images.arrow} alt="" style={{width:'5%'}}/></Link>                                 
                                            <img src={obj.banner} alt="" />
                                        </div> 
                                    )
                                })
                            }                        
                        </OwlCarousel> */}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default OurCollection;