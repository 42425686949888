import bgImg from './bg-img.png';
import about1 from './about-1.png';
import about2 from './about-2.png';
import Portfolio1 from './Portfolio-1.png';
import Portfolio2 from './Portfolio-2.png';
import Portfolio3 from './Portfolio-3.png';
import Videobg from './videoBg.png';
import play from './play.png';
import testimonialside from './testimonialside.png';
import client1 from './testimonial/client1.png';
import client2 from './testimonial/client2.png';
import arrowbtn from './arrow.png';
import star from './testimonial/Star.png';

export const home = {
    bgImg,about1,about2,Portfolio1,Portfolio2,Portfolio3,Videobg,play,testimonialside,client1,client2,star,arrowbtn
}