import React from "react";
import team from "./TeamData";

const Team = ()=>{
    return(
        <section className="teams bg-yellow pad-h-100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title" data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">
                            <h2>Team Members</h2>
                            <span>Our Tailor</span>
                        </div>
                    </div>
                    <div className="team-members row">  
                    {
                        team.map((obj,index)=>{
                            return(
                                <div className="col-lg-4 col-12 text-center" key={index}  data-aos="fade-up" data-aos-offset="250" data-aos-duration="1000">
                                    <img src={obj.img} alt={obj.head} data-aos="zoom-in" data-aos-offset="500"  data-aos-durartion="2000"/>
                                    <h3>{obj.head}</h3>
                                    <p>{obj.title}</p>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team;